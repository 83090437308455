<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div
  class="page-content-header"
  [ngStyle]="{ 'background-color': !placePreview ? '#3e3a71' : 'transparent' }"
></div>
<div class="container" *ngIf="place">
  <div class="page-content-overlay">
    <div *ngIf="!placePreview" class="py-3">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <a
            [routerLink]="[lastSearchUrl.url]"
            [queryParams]="lastSearchUrl.queryParams"
            class="btn btn-outline-light btn-lg"
            [title]="
              'BACK_TO_SOLIGUIDE_SEARCH'
                | translate : { brandName: THEME_CONFIGURATION.brandName }
            "
            (click)="
              captureEvent({
                name: 'click-go-back-last-search',
                properties: { lastSearchUrl }
              })
            "
          >
            <fa-icon [icon]="['fas', 'angle-left']" class="me-3"></fa-icon>
            <span>{{ "BACKLIST" | translate }}</span>
          </a>
        </div>

        <div class="text-end">
          <a
            *ngIf="canEdit"
            class="btn btn-outline-light btn-lg"
            [routerLink]="[routePrefix, 'manage-place', place.lieu_id]"
            (click)="captureEvent({ name: 'click-edit-place' })"
          >
            <fa-icon [icon]="['fas', 'pen']"></fa-icon>
            {{ "EDIT_PLACE" | translate }}
          </a>
        </div>
      </div>
    </div>

    <app-display-place-infos
      [place]="place"
      [canEdit]="canEdit"
      [dateForTest]="dateForTest"
      [me]="me"
      (parentCaptureEvent)="captureEvent($event)"
    ></app-display-place-infos>

    <app-place-update-campaign-banner
      *ngIf="!placePreview"
      [canEdit]="canEdit"
      [place]="place"
      (parentCaptureEvent)="captureEvent($event)"
    ></app-place-update-campaign-banner>

    <div class="alert alert-warning" *ngIf="displayInfo">
      <b>
        <fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon>
        {{ "CAMPAIGN_BANNER_MESSAGE" | translate }}
      </b>
    </div>

    <div class="page-content">
      <div class="row mb-2">
        <div class="col-xs-12 col-md-7">
          <h1 class="place_name">
            {{ place.name }}
            <span
              *ngIf="place.visibility === PlaceVisibility.PRO"
              class="bg-info badge mt-1"
            >
              {{ "PLACE_FOR_PRO_ONLY" | translate }}
            </span>
          </h1>

          <div class="place_description">
            <div class="pb-3" *ngIf="place.organizations?.length">
              <b>{{
                (place.organizations.length === 1
                  ? "ORGANIZATION"
                  : "ORGANIZATIONS"
                ) | translate
              }}</b>
              :
              <ng-container
                *ngFor="
                  let orga of place.organizations;
                  let last = last;
                  let i = index
                "
              >
                <ng-container *ngIf="i < 3">{{ orga.name }}</ng-container>
                <ng-container *ngIf="!last && i < 3">, </ng-container>
                <ng-container *ngIf="i === 3">...</ng-container>
              </ng-container>
            </div>

            <!-- Publics -->
            <app-display-publics-inline
              [publics]="place.publics"
            ></app-display-publics-inline>

            <!-- Modalités -->
            <app-display-modalities-inline
              [modalities]="place.modalities"
              (parentCaptureEvent)="captureEvent($event)"
            ></app-display-modalities-inline>

            <!-- Langues -->
            <app-display-languages
              [languages]="place.languages"
            ></app-display-languages>

            <div class="py-2" [innerHTML]="place.description"></div>
          </div>
        </div>

        <div class="col-12 col-md-5">
          <div *ngIf="place.placeType === PlaceType.ITINERARY" class="mb-3">
            <h2>{{ "HORAIRES_DEPART" | translate }}</h2>
            <app-display-temp-banner
              *ngIf="place.tempInfos.closure.actif"
              [tempInfoType]="TempInfoType.closure"
              [tempInfos]="place.tempInfos.closure"
              [displayHoursWhenTempClosed]="place.isTempClosed"
              (displayTempHoursEvent)="toogleDisplayTempHours($event)"
              (displayRegularHoursEvent)="displayRegularHours()"
              (parentCaptureEvent)="captureEvent($event)"
            ></app-display-temp-banner>
            <app-display-horaires
              [hours]="place.parcours[0].hours"
              [displayClosedDays]="true"
              [isPlace]="false"
              [isPartiallyOpen]="place.isPartiallyOpen"
              *ngIf="displayTempHours"
            >
            </app-display-horaires>
            <app-display-horaires
              [hours]="place.newhours"
              [displayClosedDays]="true"
              [isPlace]="false"
              [isPartiallyOpen]="place.isPartiallyOpen"
              *ngIf="!displayTempHours"
            >
            </app-display-horaires>
          </div>

          <div
            *ngIf="place.placeType === PlaceType.PLACE"
            class="map-container"
          >
            <app-search-map
              [markers]="marker"
              [scrollOnClick]="false"
              [withPopup]="true"
            ></app-search-map>
          </div>

          <ng-container *ngIf="place.sources">
            <app-display-sources [sources]="place.sources"></app-display-sources
          ></ng-container>

          <p class="share text-center mt-1">
            {{ "EXPORTS_HEADER_LAST_UPDATE" | translate }} :
            {{ place.updatedByUserAt | date : "longDate" }}
          </p>

          <app-share-place
            [place]="place"
            [showAddress]="showAddress"
            (parentCaptureEvent)="captureEvent($event)"
          ></app-share-place>
        </div>

        <div class="col-xs-12 col-md-7" *ngIf="place.photos.length > 0">
          <!-- Photos -->
          <h2 class="subtitle">{{ "PHOTOS_STRUCTURE" | translate }}</h2>
          <div class="ps-2">
            <app-display-photos
              [photos]="place.photos"
              [name]="place.name"
              (parentCaptureEvent)="captureEvent($event)"
            ></app-display-photos>
          </div>
        </div>
      </div>

      <div class="row my-4 col-12">
        <div
          class="col-12 col-md-6 col-lg-4"
          *ngIf="
            (place.placeType === PlaceType.PLACE && place.position?.address) ||
            place.entity?.phones?.length ||
            place.entity?.mail ||
            place.entity?.facebook ||
            place.entity?.fax ||
            place.entity?.instagram ||
            place.entity?.website
          "
        >
          <h2>{{ "INFOSPRATIQUES" | translate }}</h2>
          <app-display-entity-infos
            [place]="place"
            (parentCaptureEvent)="captureEvent($event)"
          ></app-display-entity-infos>
        </div>

        <div
          *ngIf="
            areTransportsSuggestionsEnabled &&
            place.placeType === PlaceType.PLACE &&
            place.position?.region &&
            place.position?.location?.coordinates?.length === 2 &&
            (!place.modalities.orientation?.checked || me !== null)
          "
          id="transports"
          class="col-12 col-md-6 col-lg-4"
        >
          <h2>{{ "TRANSPORTS" | translate }}</h2>

          <app-place-transports [place]="place"></app-place-transports>
        </div>

        <div
          *ngIf="place.placeType === PlaceType.PLACE"
          class="col-12 col-md-12 col-lg-4"
        >
          <h2>{{ "HOURS" | translate }}</h2>

          <app-display-holidays [place]="place"></app-display-holidays>

          <app-display-temp-banner
            *ngIf="place.tempInfos.hours.actif"
            [tempInfoType]="TempInfoType.hours"
            [tempInfos]="place.tempInfos.hours"
            [displayTempHours]="displayTempHours"
            (displayTempHoursEvent)="toogleDisplayTempHours($event)"
            (parentCaptureEvent)="captureEvent($event)"
          ></app-display-temp-banner>

          <app-display-temp-banner
            *ngIf="place.tempInfos.closure.actif"
            [tempInfoType]="TempInfoType.closure"
            [tempInfos]="place.tempInfos.closure"
            [displayHoursWhenTempClosed]="place.isTempClosed"
            (displayTempHoursEvent)="toogleDisplayTempHours($event)"
            (displayRegularHoursEvent)="displayRegularHours()"
            (parentCaptureEvent)="captureEvent($event)"
          ></app-display-temp-banner>

          <app-display-horaires
            [hours]="place.tempInfos.hours.hours"
            [displayClosedDays]="true"
            [daysMustBeOrdered]="true"
            [isTempClosed]="place.isTempClosed"
            [isPartiallyOpen]="place.isPartiallyOpen"
            *ngIf="displayTempHours"
          >
          </app-display-horaires>

          <app-display-horaires
            [hours]="place.newhours"
            [displayClosedDays]="true"
            [daysMustBeOrdered]="true"
            [isTempClosed]="place.isTempClosed"
            [isPartiallyOpen]="place.isPartiallyOpen"
            *ngIf="!displayTempHours"
          >
          </app-display-horaires>
        </div>
      </div>

      <div *ngIf="place.parcours.length" class="row my-4">
        <div class="col-12 col-md-6 col-lg-5">
          <h2>{{ "WAYPOINTS" | translate }}</h2>
          <app-display-parcours-mobile
            [place]="place"
            (parentCaptureEvent)="captureEvent($event)"
          ></app-display-parcours-mobile>
        </div>
        <div class="d-none d-lg-block col-lg-2"></div>
        <div class="col-12 col-md-6 col-lg-5">
          <div class="map-container">
            <app-search-map
              [markers]="marker"
              [scrollOnClick]="false"
              [withPopup]="true"
            ></app-search-map>
          </div>
        </div>
      </div>
    </div>

    <app-display-contacts
      *ngIf="me && (me.admin || me.pro)"
      [place]="place"
      [me]="me"
      [template]="'public'"
      (parentCaptureEvent)="captureEvent($event)"
    ></app-display-contacts>

    <app-display-services
      [services]="place.services_all"
      [dateForTest]="dateForTest"
      *ngIf="place.services_all.length"
      (parentCaptureEvent)="captureEvent($event)"
    ></app-display-services>
  </div>
</div>
<ngx-json-ld [json]="structuredData"></ngx-json-ld>
