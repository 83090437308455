<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div class="page-content-header"></div>
<div class="container">
  <div class="page-content page-content-overlay">
    <h1>Política de privacitat</h1>
    <h2>Quina utilitat té aquesta política?</h2>

    <p>
      <strong>
        Solinum, responsable del web
        <a href="https://soliguia.eu">soliguia.eu</a> ("Soliguia"), atorga una
        gran importància a la protecció i confidencialitat de les vostres dades
        personals, el qual per nosaltres representa una garantia de credibilitat
        i confiança.
      </strong>
    </p>
    <p>
      La política de privacitat de les dades reflecteix precisament la nostra
      <strong
        >voluntat de respectar, des de Solinum, les normes aplicables en matèria
        de protecció de dades</strong
      >
      i, més concretament, les del Reglament General de Protecció de Dades
      ("RGPD").
    </p>
    <p>
      En concret, la política de privacitat té per objectiu informar-vos de la
      manera i les raons per les quals tractarem les vostres dades en relació
      amb els <b>serveis</b> que oferim.
    </p>

    <h2>A qui va dirigida aquesta política?</h2>

    <p>
      La política se li aplica a <b>vostè</b>, independentment d’on visqui, si
      és un <b>visitant</b> del nostre lloc web o
      <b>usuari del nostre servei</b> Soliguide.
    </p>

    <p>
      <strong
        >Fem saber als particulars menors de 15 anys que no poden utilitzar els
        nostres serveis sense l’autorització prèvia dels seus pares, els quals
        s’han d’adreçar per escrit a
        <a href="mailto:dipeeo@solinum.org">dipeeo&#64;solinum.org</a></strong
      >. Si creieu que podem disposar d’informació sobre els vostres fills
      menors de 15 anys sense haver-ho consentit, us demanem que us poseu en
      contacte amb nosaltres.
    </p>

    <h2>Per què tractem les vostres dades?</h2>

    <p>
      Per poder oferir els nostres serveis, ens cal tractar les vostres dades
      personals perquè pugueu:
    </p>
    <ul>
      <li>
        <b>utilitzar i beneficiar-vos dels nostres serveis</b> (p. ex., cerca de
        serveis, indexació d’organitzacions, etc.)
        <b>i totes les seves funcions</b> d’acord amb les nostres condicions
        generals d’ús.
      </li>
      <li>
        <b>garantir la gestió de comptes dels administradors i usuaris</b> (p.
        ex., creació de comptes, accés al servei i supressió de comptes) d’acord
        amb les nostres condicions generals d’ús.
      </li>
      <li>
        <b
          >comunicar-vos amb el nostre servei de suport a través de la nostra
          missatgeria interna</b
        >
        d’acord amb les nostres condicions generals d’ús.
      </li>
      <li>
        <b
          >rebre els nostres correus tècnics (p. ex., modificació de
          contrasenya, etc.)</b
        >
        indispensables pel bon funcionament del nostre servei d’acord amb els
        nostres condicions generals d’ús.
      </li>
      <li>
        <b
          >seguir-nos i comentar les nostres publicacions a les xarxes
          socials</b
        >
        d’acord amb el nostre interès legítim de beneficiar-nos d’una pàgina
        dedicada a les xarxes socials.
      </li>
      <li>
        <b>poder penjar i importar documents a la nostra plataforma</b> d’acord
        amb les nostres condicions generals d’ús.
      </li>
      <li>
        <b>situar la vostra ubicació</b> quan utilitzeu el nostre servei per tal
        d’oferir-vos un servei personalitzat i adaptat, segons el vostre
        consentiment.
      </li>
      <li>
        <b
          >garantir i reforçar la seguretat i la qualitat dels nostres
          serveis</b
        >
        diàriament (p. ex., estadístiques, seguretat de les dades, etc.) segons
        les obligacions legals que recauen sobre nostre, les nostres condicions
        generals d’ús i el nostr interès legítim de garantir el bon funcionament
        dels nostres serveis.
      </li>
    </ul>

    <p>
      Les vostres dades es recullen <b>directament de vostè</b> quan utilitzeu
      el nostre servei i ens comprometem a tractar-les únicament pels
      <b>motius descrits anteriorment</b>.
    </p>

    <p>
      Tot i això, ja que publiqueu voluntàriament el contingut a les pàgines que
      editem a les xarxes socials, reconeixeu que sou
      <b>completament responsables</b> de tota la informació personal que pugueu
      transmetre, independentment de la naturalesa i l’origen de la informació
      proporcionada.
    </p>

    <p>
      Pel que fa a les galetes, us preguem que consulteu la nostra
      <a [title]="linkTitle" [routerLink]="[routePrefix, cookiePolicyLink]"
        ><b>política de galetes</b></a
      >
      dedicada accessible al nostre lloc web.
    </p>

    <h2>Quines dades i durant quant temps?</h2>

    <p>
      Hem resumit les <b>categories de dades personals</b> que recollim així com
      les <b>durades d’emmagatzematge respectives</b>.
    </p>
    <p>
      Si voleu obtenir encara més detalls sobre les
      <b>durades d’emmagatzematge</b> aplicables de les vostres dades, ens podeu
      contactar a través de l’adreça:
      <a href="mailto:dipeeo@solinum.org">dipeeo&#64;solinum.org</a>.
    </p>

    <ul>
      <li>
        <b>Pels particulars, dades personals d’identificació</b> (p. ex.,
        cognom, nom) i <b>de contacte</b> (p. ex., adreça de correu electrònic)
        que s’emmagatzemen durant tota la durada de la prestació de servei a la
        qual s’afegeixen els terminis legals de caducitat que en general són de
        5 anys.
      </li>
      <li>
        <b>Pels professionals, dades personals d’identificació</b> (p. ex.,
        cognom, nom, posició, empresa, servei, etc.) i <b>de contacte</b> (p.
        ex., adreça de correu electrònic i telèfon professional, etc.)
        emmagatzemades durant tota la durada de la prestació de servei a la qual
        s’afegeixen els terminis legals de caducitat que en general són de 5
        anys.
      </li>
      <li>
        <b
          >L’adreça de correu electrònic per rebre els nostres missatges
          tècnics</b
        >
        s’emmagatzema fins que suprimiu el vostre compte.
      </li>
      <li>
        <b>Dades de connexió</b> (ex : registres, adreça IP, etc.)
        emmagatzemades durant 1 any.
      </li>
      <li>
        <b>Galetes</b> que en general s’emmagatzemen un màxim de 13 mesos. Per
        més detalls sobre l’ús que fem de les vostres galetes, podeu consultar
        la nostra
        <a [title]="linkTitle" [routerLink]="[routePrefix, cookiePolicyLink]"
          ><b>política de galetes</b></a
        >.
      </li>
    </ul>

    <p>
      Quan expirin els terminis d’emmagatzematge descrits aquí, la supressió de
      les vostres dades personals és <b>irreversible</b> i no us les podrem
      comunicar quan hagi passat el termini. Com a màxim, podem conservar
      únicament les dades anònimes per finalitats <b>estadístiques</b>.
    </p>
    <p>
      Tingueu en compte que en cas de <b>litigi</b>, tenim l’obligació de
      conservar la <b>totalitat</b> de les vostres dades mentre duri la
      tramitació de l’expedient fins i tot si han caducat els terminis de
      d’emmagatzematge descrits anteriorment.
    </p>

    <h2>De quins drets disposeu per controlar l’ús de les vostres dades?</h2>

    <p>
      La normativa aplicable en matèria de protecció de dades us atorga
      <b>drets específics</b> que podeu exercir, <b>sempre</b> i
      <b>gratuïtament</b>, per tal de controlar l’ús que fem de les vostres
      dades.
    </p>
    <ul>
      <li>
        Dret a l’<b>accés</b> i la <b>còpia</b> de les vostres dades personals
        sempre que aquesta petició no es contradigui amb els secretes
        empresarials, la confidencialitat o el secret de la correspondència.
      </li>
      <li>
        Dret de <b>rectificació</b> de les dades personals que siguin errònies,
        obsoletes o incompletes.
      </li>
      <li>
        Dret de demanar la <b>supressió</b> ("dret a l’oblit") de les vostre
        dades personals que no siguin essencials per al bon funcionament dels
        nostres serveis.
      </li>
      <li>
        Dret a la <b>limitació</b> de les vostres dades personals que us permet
        limitar l’ús les vostres dades en cas de litigi sobre la legitimitat
        d’un tractament
      </li>
      <li>
        Dret a la <b>portabilitat</b> de les vostres dades que us permet
        recuperar una part de les vostres dades personals per tal
        d’emmagatzemar-les o transmetre-les fàcilment d’un sistema d’informació
        a un altre.
      </li>
      <li>
        Dret de marcar unes <b>directrius</b> sobre què fer amb les vostres
        dades en cas de defunció ja sigui l’intermediari vostè, un tercer de
        confiança o una persona amb el dret.
      </li>
    </ul>

    <p>
      Perquè es <b>tingui en compte</b> una sol·licitud, cal que la faci vostè
      directament a l’adreça
      <a href="mailto:dipeeo@solinum.org">dipeeo&#64;solinum.org</a>. Qualsevol
      sol·licitud que no es faci d’aquesta manera <b>no es tramitarà</b>.
    </p>

    <p>
      Les sol·licituds no les poden fer altres persones. Per aquest motiu, pot
      ser que us demanem que ens envieu una <b>prova d’identitat</b> en cas de
      dubte sobre la identitat de la persona sol·licitant.
    </p>

    <p>
      Rebreu la resposta de la vostra petició
      <b>amb el mínim de temps possible</b>, abans que passi <b>un mes</b> a
      partir de la recepció de la sol·licitud, excepte si aquesta resulta
      complexa o està repetida. En aquest cas, el termini de resposta pot ser de
      <b>tres mesos com a màxim</b>.
    </p>

    <p>
      Tingueu en compte que podem <b>rebutjar</b> respondre tota sol·licitud
      <b>excessiva o infundada</b>, sobretot si té un caràcter <b>repetitiu</b>.
    </p>

    <h2>Qui pot accedir a les vostres dades?</h2>

    <p class="text-uppercase">
      <strong
        >NO TRANSFERIREM NI VENDREM MAI LES VOSTRES DADES A TERCERS O SOCIS
        COMERCIALS. LA TOTALITAT DE LES VOSTRES DADES PERSONALS L’UTILITZA
        EXCLUSIVAMENT EL NOSTRE EQUIP O EL PROVEIDOR INFORMÀTIC.</strong
      >
    </p>

    <p>
      Més concretament, només comunicarem les vostres dades a persones
      <b>degudament autoritzades</b> perquè les utilitzin per proveir-vos els
      nostres serveis, com ara el nostre servei informàtic o el servei
      encarregat de la relació amb el client.
    </p>

    <p>
      Les vostres dades personals també es transfereixen als nostres proveïdors
      informàtics només per fer funcionar el nostre servei, com ara
      l’allotjament de dades o la nostra eina d’enviament de correus tècnics.
    </p>

    <p>
      Us fem saber que
      <b
        >controlem tots els nostres proveïdors informàtics abans de
        contractar-los</b
      >
      per tal d’assegurar-nos que respectin escrupolosament la normativa
      aplicable en matèria de protecció de dades personals.
    </p>

    <h2>Com protegim les vostres dades?</h2>

    <p>
      Apliquem tots els <b>mitjans tècnics</b> i
      <b>organitzatius necessaris</b> per garantir la <b>seguretat</b> de les
      vostres dades en tot moment i, en particular, per combatre el risc de
      destrucció, pèrdua, alteració o divulgació no autoritzada de les vostres
      dades (p. ex., formació, control d’accés, contrasenyes, etc.).
    </p>

    <h2>Les vostres dades poden ser transferides a fora de la Unió Europea?</h2>

    <p>
      Excepte si esdevé estrictament necessari i de manera excepcional, no
      transferirem mai les vostres dades fora de la Unió Europea i s’allotjaran
      sempre en <b>territori europeu</b>. A més a més, ens esforcem al màxim per
      contractar proveïdors que només allotgin les vostres dades en el si de la
      Unió Europea.
    </p>

    <p>
      En els casos en què haguem d’utilitzar proveïdors situats fora de la Unió
      Europea, vetllem escrupolosament perquè apliquin les
      <b
        >garanties adequades per tal d’assegurar la confidencialitat i la
        protecció de les vostres dades</b
      >. D’altra banda, ens comprometem a concretar amb aquests proveïdors
      clàusules contractuals tipus redactades per la Comissió europea per tal de
      limitar les transferències.
    </p>

    <h2>A qui podeu contactar per a obtenir més informació?</h2>

    <p>
      Podeu contactar en tot moment i gratuïtament el nostre DPO a l’adreça
      <a href="mailto:dipeeo@solinum.org">dipeeo&#64;solinum.org</a> per tal
      d’obtenir més informació o detalls sobre com tractem les vostres dades.
    </p>

    <p>
      Per garantir una millor protecció i integritat de les vostres dades, hem
      nomenat oficialment un
      <b>Delegat de protecció de dades ("DPO") independent</b> de la nostra
      autoritat supervisora.
    </p>

    <h2>Com podeu contactar la CNIL?</h2>

    <p>
      Sempre podeu contactar la "<b
        >Commission nationale de l'informatique et des libertés</b
      >" (en català, "Comissió nacional d’informàtica i llibertats") o
      "<b>CNIL</b>" a la direcció següent: Service des plaintes de la CNIL, 3
      place de Fontenoy - TSA 80751, 75334 Paris Cedex 07 o per telèfon al
      <a href="tel:+33153732222">+33(0)1.53.73.22.22</a>.
    </p>

    <h2>La política pot ser modificada?</h2>

    <p>
      Podem <b>modificar</b> la nostra política de privacitat
      <b>en tot moment</b> per adaptar-la a noves <b>exigències legals</b> i
      <b>noves tramitacions</b> que siguin aplicables en un futur.
    </p>

    <p>
      <em
        >Certificat conforme
        <a href="https://www.dipeeo.com/"><u>Dipeeo</u></a> &reg;</em
      >
    </p>
  </div>
</div>
