<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div class="page-content-header"></div>
<div class="container">
  <div class="page-content page-content-overlay">
    <h1>Annex de protecció de dades</h1>

    <h2>1. Introducció</h2>
    <p>
      L´annex de protecció de dades (a continuació "<b>APD</b>" o
      "<b>Acord</b>") té per objectiu controlar la utilització de les dades amb
      caràcter personal del client, que actua com a responsable del tractament
      (a continuació el "<b>Client</b>"), per Solinum que actua com a
      subcontractat (a continuació el "<b>Subcontractat</b>") en el marc del
      contracte (a continuació el "<b>Contracte</b>").
    </p>
    <p>
      L´APD és una part integrant del contracte signat entre el client i el
      subcontractat. En el cas de contradicció entre el contracte i l´APD, les
      obligacions previstes en l´APD prevalen quant a les regles aplicables en
      matèria de protecció de dades.
    </p>
    <p>
      Tots els termes relatius a la protecció de dades utilitzada l´APD (ex: el
      responsable del tractament, el subcontractat, etc...) es defineixen a
      l´article 4rt del Reglament general per a la protecció de dades
      ("<b>RGPD</b>").
    </p>

    <h2>2. Declaració</h2>
    <p>
      El subcontractat declara respectar íntegrament la reglamentació aplicable
      en matèria de protecció de dades que compren el reglament (UE) 2016/679
      del Parlament Europeu i del Consell del 27 d'abril de 2016, relatiu a la
      protecció de les persones físiques en relació a la protecció del
      tractament de les dades amb caràcter personal i a la lliure circulació
      d´aquestes dades ("<b>RGPD</b>") i la llei n° 78-17 del 6 de gener de
      1978, relativa a la informàtica, als fitxers i a les llibertats, tot
      plegat anomenat
      <b>"regles aplicables en matèria de protecció de dades"</b>.
    </p>
    <p>
      El subcontractat declara presentar les <b>garanties suficients</b> per a
      respondre a les exigències de la reglamentació aplicable en matèria de
      protecció de dades, i, a més, particularment, per a
      <b>garantir la confidencialitat i la protecció de dades del client</b>.
    </p>
    <p>
      El subcontractat declara que la totalitat dels seus col·laboradors que han
      de tractar les dades amb caràcter personal del client estan subjectes a
      una <b>clàusula de confidencialitat</b> o a qualsevol altre acte jurídic
      (ex: regles de bona conducta, estatut dels sistemes informatius, etc...)
      que permeten garantir la confidencialitat des les dades amb caràcter
      personal del client.
    </p>
    <p>
      El subcontractat declara <b>formar i sensibilitzar</b> regularment els
      seus col·laborador sobre la reglamentació aplicable en matèria de
      protecció de dades.
    </p>

    <h2>3. Instruccions</h2>
    <p>
      El subcontractat es compromet a no utilitzar les dades amb caràcter
      personal del client sense <b>les instruccions documentades</b> d´aquest
      últim.
    </p>
    <p>
      El client es compromet a informar el subcontractat de qualsevol
      modificació de les instruccions que poguessin realitzar-se respecte a la
      utilització de les dades amb caràcter personal.
    </p>
    <p>
      Cal que el subcontractat notifiqui, al més aviat possible, si les
      instruccions documentades d´aquest últim constitueixen una violació de la
      reglamentació aplicable en matèria de protecció de dades.
    </p>

    <h2>4. Conformitat per defecte i des del seu disseny</h2>
    <p>
      El subcontractat exerceix el seu servei tal qual, en el respecte:
      <b
        >i) de la conformitat del servei des del seu disseny, i ii) de la
        conformitat del servei per defecte</b
      >.
    </p>
    <p>
      El subcontractat ofereix un servei acompanyat de
      <b>totes les funcions que permeten al client respectar les obligacions</b>
      com a responsable del tractament.
    </p>
    <p>
      En conseqüència, el subcontractat mai
      <b
        >no és responsable de la utilització de la no-conformitat de la
        reglamentació aplicable en matèria de protecció de dades del servei pel
        client</b
      >.
    </p>

    <h2>5. Seguretat</h2>
    <p>
      El subcontractat es compromet a garantir la <b>seguretat</b> de les dades
      amb caràcter personal del client i activar totes les
      <b>mesures tècniques i organitzatives necessàries</b> per preveure tots
      els riscos de la violació de les dades.
    </p>

    <h2>6. Violació de les dades</h2>
    <p>
      El subcontractat es compromet a notificar al client,
      <b>al més aviat possible</b>, i en 48 hores laborables, després d´haver
      conegut la violació de les dades que seria susceptible d'afectar les dades
      amb caràcter personal del client.
    </p>
    <p>
      El subcontractat es compromet a proporcionar al client, amb conformitat a
      les disposicions de l´article 28 de RGPD, totes les informacions
      necessàries sobre el tractament de la violació de les dades del client.
    </p>
    <p>
      En cas de violació de les dades, el subcontractat es compromet a prendre
      totes les mesures necessàries per <b>compensar i disminuir</b> l´impacte
      de la violació de les dades amb caràcter personal del client.
    </p>
    <p>
      Excepte acord exprés, previ i escrit del client, el subcontractat
      <b>no està autoritzat</b> a fer-se càrrec de cap notificació de violacions
      de les dades davant de la autoritat de control francesa, la CNIL. Així
      mateix, el subcontractat no està tampoc autoritzat a informar en nom del
      client les persones afectades pel tractament realitzat en el marc del
      contracte.
    </p>

    <h2>7. Suport i assistència en matèria de seguretat</h2>
    <p>
      El subcontractat comunicarà al client, prèvia sol·licitud escrita,
      <b
        >la informació necessària i requerida sobre les mesures de seguretat
        tècniques i organitzatives</b
      >
      per aplicar i garantir la seguretat de les dades amb caràcter personal.
    </p>
    <p>
      El subcontractat comunicarà al client, prèvia sol·licitud escrita,
      <b>la informació necessària i requerida</b> per assegurar la realització
      d´una <b>anàlisi de l´impacte ("AIPD")</b> en relació directa amb el
      servei proporcionat.
    </p>
    <p>
      El subcontractat no està obligat a garantir o auditar la seguretat del
      client ni realitzar els AIPD en lloc o en nom del client. Qualsevol
      demanda complementària de la comunicació de la informació podrà ser
      objecte de rebuig i, eventualment, d´una prestació complementària pagant.
    </p>

    <h2>
      8. Suport i assistència d'acord amb els drets des les persones afectades
    </h2>
    <p>
      El subcontractat comunicarà al client, prèvia sol·licitud escrita,
      <b>la informació necessària i requerida</b> perquè el client pugui complir
      amb la seva obligació de respondre a les sol·licituds de les persones
      afectades.
    </p>
    <p>
      El subcontractat executarà, prèvia sol·licitud escrita del client, les
      <b>accions tècniques</b> que caldrà prendre perquè el client pugui complir
      amb la seva obligació de respondre a les <b>sol·licituds</b> de les
      persones afectades.
    </p>
    <p>
      El subcontractat no estarà obligat a gestionar les sol·licituds dels drets
      de les perdones en lloc o en nom del client del client. Qualsevol
      sol·licitud complementària de cara a garantir aquestes gestions podrà ser
      objecte de rebuig i, eventualment, d´una prestació complementària pagant.
    </p>

    <h2>9. Subcontractats posteriors</h2>
    <p>
      De manera general, el client acceptarà del subcontractat que convoqui
      altres <b>subcontractats posteriors</b> en el marc de l'execució del
      contracte a condició d´informar el client de qualsevol canvi que afecti
      els subcontractats posteriors que intervinguin durant l'execució del
      contracte.
    </p>
    <p>
      El client pot emetre <b>objeccions</b> per carta certificada amb rebut de
      recepció: i) si el subcontractat posterior és un dels concurrents; ii) si
      el client i el subcontractat es troben en una situació de
      <b>pre-contenciós o contenciós</b>, i iii) si el subcontractat posterior
      ha estat objecte d'una condemna per la autoritat que controla en matèria
      de protecció de dades durant l´any de la convocatòria del subcontractat.
      Cal <b>demostrar</b> cadascuna d´aquestes situacions.
    </p>
    <p>
      Si s'admetés l'objecció, el subcontractat disposaria d´un període de 6
      mesos a partir de la recepció de l´objecció per modificar el subcontractat
      posterior o garantir el respecte de la reglamentació aplicable en matèria
      de protecció de dades per aquest subcontractat posterior.
    </p>
    <p>
      Els tots els casos, el subcontractat es comprometrà a convocar només
      subcontractats posteriors que presentin
      <b>les garanties necessàries i suficients</b> per garantir la seguretat i
      la confidencialitat de les dades del client.
    </p>
    <p>
      En aquest sentit, el subcontractat es comprometrà a: i) controlar
      regularment els seus subcontractats posteriors i ii) que el contracte
      signat amb el subcontractat posterior utilitzat en el marc del servei
      contingui <b>obligacions semblants</b> a aquelles previstes a l´APD.
    </p>
    <p>
      En qualsevol cas, el subcontractat serà responsable dels actes del
      subcontractat posterior en el marc del contracte.
    </p>

    <h2>10. Tipus de dades amb caràcter personal</h2>
    <p>
      El client informarà al subcontractat, per escrit i abans de finalitzar el
      contracte la relació comercial, la seva elecció (opció 1) de proporcionar
      les dades personals i, seguidament, de suprimir-les, així com totes les
      còpies existents, o (opció 2) suprimir directament les dades personals amb
      totes les còpies existents, o (opció 3) transferir les dades personals a
      un nou proveïdor i després suprimir-les juntament amb les còpies
      existents. Excepte una disposició contraria del contracte, la opció 3
      caldrà que sigui pressupostada pel subcontractat.
    </p>
    <p>
      Si el client no fa part de la seva opció, el subcontractat suprimirà
      directament les dades del client i totes les còpies (opció 2) per
      concloure la relació comercial.
    </p>
    <p>
      La supressió de les dades és irreversible. S´invitarà el client a
      recuperar les seves dades abans d´acabar el servei. En el cas de la
      supressió de les dades del client per part del subcontractat, el client
      esdevé l´únic responsable de la desaparició de les seves dades i de les
      conseqüències que puguin derivar-se.
    </p>
    <p>
      El subcontractat ratificarà al client, prèvia sol·licitud escrita, la
      supressió efectiva de les dades amb caràcter personal i de totes les
      còpies existents.
    </p>

    <h2>11. Auditories</h2>
    <p>
      El client disposa del dret a realitzar una auditoria sota la forma de
      qüestionari escrit una vegada a l´any per verificar el respecte del
      present acord. El qüestionari te la força d´un compromís sota l´honor que
      compromet el subcontractat.
    </p>
    <p>
      Es comunicarà, sota qualsevol forma, el qüestionari al subcontractat que
      es comprometrà a respondre-hi en un termini màxim de 2 mesos a partir de
      la seva recepció.
    </p>
    <p>
      El cient disposa igualment del dret a realitzar una auditoria en el lloc
      mateix, al seu càrrec, un cop per any, únicament en el cas de violació de
      les dades o mancança de reglamentació aplicable en matèria de protecció de
      dades i en el present ADP, concretament establert pel qüestionari escrit.
    </p>
    <p>
      Aquesta auditoria en el lloc mateix pot fer-se pel client o per un tercer
      independent designat pel client i ha de notificar-se per escrit al
      subcontractat en un termini mínim de trenta dies (30) abans de realitzar
      la dita auditoria.
    </p>
    <p>
      El subcontractat disposa del dret a rebutjar l'elecció del tercer
      independent si aquest darrer és: i) un concurrent, o ii) en pre-contenciós
      o contenciós amb ell/ella. En aquest cas, el client es comprometrà a
      escollir un nou tercer independent per realitzar la auditoria.
    </p>
    <p>
      El subcontractat pot <b>rebutjar</b> l´accés a certes àrees per raons de
      confidencialitat o de seguretat. En aquest cas, el subcontractat efectuarà
      l'auditoria en àrees al seu càrrec i comunicarà els resultats al client.
    </p>
    <p>
      En cas de discrepar en el marc de l'auditoria, el subcontractat es
      compromet a aplicar <b>sense tardar</b>, les mesures necessàries de
      conformitat amb el present acord.
    </p>

    <h2>12. Transferir les dades a la Unió Europea</h2>
    <p>
      El subcontractat es comprometrà a fer el que calgui per
      <b
        >no transferir les dades de caràcter personal del client fora de la Unió
        Europea</b
      >
      o no agafar subcontractats posteriors situats fora de la Unió Europea.
    </p>
    <p>
      Tanmateix, en el cas que aquestes transferències esdevinguin necessàries
      en el marc del contracte, el subcontractat es comprometrà a activar tots
      els mecanismes requerits per controlar aquestes transferències com,
      concretament, signar clàusules tipus de protecció de dades ("CCT")
      adoptades per la Comissió Europea.
    </p>

    <h2>13. Cooperació amb la autoritat de control</h2>
    <p>
      Quan aquesta afecti els tractaments realitzats dintre del marc del
      contracte, el subcontractat es comprometrà a facilitar, prèvia
      sol·licitud, la informació requerida pel client a fi que ell/ella pugui
      col·laborar amb <b>la autoritat de control competent</b>.
    </p>

    <h2>14. Contacte</h2>
    <p>
      El client i el subcontractat designaran cadascú/una un/una interlocutor/a
      que es responsabilitzi del present ADP i que sigui el destinatari de
      diferents notificacions i comunicacions havent d´intervenir en el marc de
      la APD
    </p>
    <p>
      El subcontractat informa el client que ell/a ha designat la societat
      Depeeo com a delegada de la protecció de dades i que pot posar-s'hi en
      contacte a través de:
    </p>
    <ul>
      <li>
        Adreça del correu electrònic:
        <a href="mailto:dipeeo@solinum.org">dipeeo&#64;solinum.org</a>
      </li>
      <li>
        Adreça postal: Societat Dipeeo SAS, Avenue de la Résistance 104, 93100
        Montreuil, France
      </li>
      <li>
        Numero de telèfon: <a href="tel:+33986232129">+33(0)9 86 23 21 29</a>
      </li>
    </ul>

    <h2>15. Revisió</h2>
    <p>
      El subcontractat es reservarà la possibilitat de modificar aquest acord en
      el cas de l'evolució de la reglamentació aplicable en matèria de protecció
      de dades que tingués per efecte modificar qualsevol de les seves
      disposicions.
    </p>

    <h2>16. Legislació aplicable</h2>
    <p>
      Malgrat qualsevol disposició contrària prevista en aquest contracte, el
      present acord resta subjecte a la <b>legislació francesa</b>. Qualsevol
      litigi relatiu a l'execució del present acord és competència exclusiva
      dels jutjats de la jurisdicció de la
      <b>Cort d´Apel·lació del lloc de domiciliació del subcontractat</b>.
    </p>
    <p>
      Tanmateix, en el cas que tals transferències fossin necessàries en el marc
      del contracte, el subcontractat es comprometrà a activar tots els
      mecanismes exigits per emmarcar aquestes transferències com, concretament,
      signar clàusules tipus de protecció de dades ("CCT") adoptades per la
      Comissió Europea.
    </p>

    <h2>Annex 1 - Descripció dels tractaments</h2>
    <table class="table">
      <thead class="bg-primary text-white">
        <tr>
          <th scope="col">Tractaments</th>
          <th scope="col">Fonaments</th>
          <th scope="col">Persones afectades</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Banda de galetes
          </td>
          <td>Obligació legal</td>
          <td>Usuaris i administradors</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Conversa / conversa (suport)
          </td>
          <td>Interès legítim</td>
          <td>Usuaris i administradors</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Galetes estadístiques
          </td>
          <td>Consentiment previ</td>
          <td>Usuaris i administradors</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Galetes tècniques de sessió
          </td>
          <td>Interès legítim</td>
          <td>Usuaris i administradors</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Creació del compte
          </td>
          <td>Execució del contracte</td>
          <td>Usuaris i administradors</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Formulari de contacte
          </td>
          <td>Negociació pre-contractual i Interès legítim</td>
          <td>Usuaris i administradors</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Geolocalització
          </td>
          <td>Consentiments previ</td>
          <td>Usuaris i administradors</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Gestió de bases de dades
          </td>
          <td>Execució del contracte</td>
          <td>Usuaris i administradors</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Gestió des comptes
          </td>
          <td>Execució del contracte</td>
          <td>Usuaris i administradors</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">Allotjament</td>
          <td>Cf. Fitxes fonament de l'allotjament del registre</td>
          <td>Usuaris i administradors</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Informació mobilitat
          </td>
          <td>Interès legítim</td>
          <td>Usuaris i administradors</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Inscripció i connexió
          </td>
          <td>Execució del contracte</td>
          <td>Usuaris i administradors</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Invitació dels usuaris
          </td>
          <td>Execució del contracte</td>
          <td>Usuaris i administradors</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Missatges tècnics ou transaccionals per e-mail
          </td>
          <td>Interès legítim</td>
          <td>Usuaris i administradors</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Compartir les fitxes de preparació
          </td>
          <td>Interès legítim</td>
          <td>Usuaris i administradors</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Personalització del compte
          </td>
          <td>Execució del contracte</td>
          <td>Usuaris i administradors</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Seguretat i manteniment
          </td>
          <td>Obligació legal i interès legítim/td></td>
          <td>Usuaris i administradors</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Servidors de suport
          </td>
          <td>Cf. Fitxes fonament de l'allotjament del registre</td>
          <td>Usuaris i administradors</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Descàrrega i importació de documents
          </td>
          <td>Execució del contracte</td>
          <td>Usuaris i administradors</td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Traducció del contingut
          </td>
          <td>Interès legítim</td>
          <td>Usuaris i administradors</td>
        </tr>
      </tbody>
    </table>

    <h2>
      Annex 2 - Descripció de les dades tractades i duració de la conservació
    </h2>
    <table class="table">
      <thead class="bg-primary text-white">
        <tr>
          <th scope="col">Categories de les dades</th>
          <th scope="col">Finalitat</th>
          <th scope="col">Durada de conservació</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td scope="row">
            Dades d'identificació dels administradors (ex: nom, cognom)
          </td>
          <td>Utilització del servei</td>
          <td>
            Arxivar les dades dels administradors durant 5 ans un cop la relació
            comercial acabada
          </td>
        </tr>
        <tr>
          <td scope="row">
            Dades d'identificació dels usuaris (ex: cognom, nom)
          </td>
          <td>Utilització del servei</td>
          <td>
            Fer anònimes o suprimir les dades dels usuaris amb la supressió del
            compte
          </td>
        </tr>
        <tr>
          <td scope="row">
            Dades dels administradors (ex: e-mail, número de telèfon)
          </td>
          <td>Utilització del servei</td>
          <td>
            Arxivar les dades dels administradors durant 5 ans un cop la relació
            comercial acabada
          </td>
        </tr>
        <tr>
          <td scope="row">
            Dades dels administradors (ex: e-mail, número de telèfon)
          </td>
          <td>Utilització del servei</td>
          <td>
            Fer anònimes o suprimir les dades dels Usuaris amb la supressió del
            compte
          </td>
        </tr>
        <tr>
          <td scope="row">
            Dades dels administradors (ex: e-mail, número de telèfon)
          </td>
          <td>Notificacions tècniques i transaccionals</td>
          <td>
            Arxivar les dades dels administradors durant 5 ans un cop la relació
            comercial acabada
          </td>
        </tr>
        <tr>
          <td scope="row">Dades dels administradors (ex: e-mail)</td>
          <td>Notificacions tècniques i transaccionals</td>
          <td>
            Fer anònimes o suprimir les dades dels usuaris amb la supressió del
            compte.
          </td>
        </tr>
        <tr>
          <td scope="row">
            Dades relatives a la vida professional dels administradors (ex:
            empresa, servei, funció)
          </td>
          <td>Utilització del servei</td>
          <td>
            Arxivar les dades dels administradors durant 5 ans un cop la relació
            comercial acabada
          </td>
        </tr>
        <tr>
          <td scope="row">
            Dades relatives a la vida professional dels usuaris (ex: empresa,
            servei, funció)
          </td>
          <td>Utilització del servei</td>
          <td>
            Fer anònimes o suprimir les dades dels Usuaris amb la supressió del
            compte.
          </td>
        </tr>
        <tr>
          <td scope="row">
            Dades de connexió (ex: identificadors, adreça de l'IP, logs)
          </td>
          <td>Utilització del servei</td>
          <td>
            Suprimir o fer anònimes els logs i les adreces IP de connexió cada
            12 mesos màxim.
          </td>
        </tr>
        <tr>
          <td scope="row">Galetes tècniques de sessió</td>
          <td>Utilització del servei</td>
          <td>Suprimir o fer anònimes les galetes cada 13 mesos màxim.</td>
        </tr>
        <tr>
          <td scope="row">Galetes estadístiques</td>
          <td>Anàlisi de la utilització del servei</td>
          <td>Suprimir o ignorar les galetes cada 13 mesos màxim.</td>
        </tr>
      </tbody>
    </table>

    <h2>Annex 3 - Mesures de seguretat tècniques i organitzatives</h2>
    <table class="table">
      <thead class="bg-primary text-white">
        <tr>
          <th scope="col">Categories de les mesures</th>
          <th scope="col">Mesures de seguretat integrades</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td scope="row">Mesura de seguretat tècnica</td>
          <td>Encriptació de les contrasenyes dels clients / usuaris</td>
        </tr>
        <tr>
          <td scope="row">Mesura de seguretat tècnica</td>
          <td>Aplicació en línia / plataforma en línia en HTTPS</td>
        </tr>
        <tr>
          <td scope="row">Mesura de seguretat tècnica</td>
          <td>Desconnexió automàtica després de cert temps d´inactivitat</td>
        </tr>
        <tr>
          <td scope="row">Mesura de seguretat tècnica</td>
          <td>reCAPTCHA</td>
        </tr>
        <tr>
          <td scope="row">Mesura de seguretat organitzativa</td>
          <td>Formació dels equips</td>
        </tr>
        <tr>
          <td scope="row">Mesura de seguretat organitzativa</td>
          <td>Carta dels sistemes d'informació</td>
        </tr>
        <tr>
          <td scope="row">Mesura de seguretat organitzativa</td>
          <td>Regles de bona conducta</td>
        </tr>
      </tbody>
    </table>

    <h2>
      Annex 4 - Llista dels sous-tractants i de les transferències fora de la UE
    </h2>
    <table class="table">
      <thead class="bg-primary text-white">
        <tr>
          <th scope="col">Sub-contracts</th>
          <th scope="col">Funcionalitats</th>
          <th scope="col">Localit zació dels servidors</th>
          <th scope="col">Transferències fora de la UE</th>
          <th scope="col">Garanties adequades</th>
          <th scope="col">Coordenades</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">Crisp</td>
          <td>Chat / chatbot</td>
          <td>França</td>
          <td>Transferències fora de la UE (NA)</td>
          <td>Marc de les transferències (NA)</td>
          <td>
            <a href="mailto:dpo@crisp.chat">dpo&#64;crisp.chat</a>
          </td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">Crisp</td>
          <td>Galetes estadístiques</td>
          <td>França</td>
          <td>Transferències fora de la UE (NA)</td>
          <td>Marc de les transferències (NA)</td>
          <td>
            <a href="mailto:dpo@crisp.chat">dpo&#64;crisp.chat</a>
          </td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Dagster Cloud
          </td>
          <td>Allotjament</td>
          <td>França</td>
          <td>Transferències fora de la UE (NA)</td>
          <td>Marc de les transferències (NA)</td>
          <td>
            <a href="mailto:privacy@elementl.com">privacy&#64;elementl.com</a>
          </td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Google Analytics
          </td>
          <td>Galetes estadístiques</td>
          <td>Estats-Units</td>
          <td>Transferències vers els Estats-Units</td>
          <td>
            <a href="https://business.safety.google/adsprocessorterms/sccs/p2p/"
              >SCC de Google Ads</a
            >
          </td>
          <td>
            <a href="mailto:data-protecció-office@google.com"
              >data-protecció-office&#64;google.com</a
            >
          </td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">Google Maps</td>
          <td>Mapping / geolocalització</td>
          <td>Estats-Units</td>
          <td>Transferències vers els Estats-Units</td>
          <td>
            <a href="https://policies.google.com/privacy/frameworks?hl=fr"
              >SCC de Google</a
            >
          </td>
          <td>
            <a href="mailto:data-protecció-office@google.com"
              >data-protecció-office&#64;google.com</a
            >
          </td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">Navitia</td>
          <td>Informació mobilitat</td>
          <td>França</td>
          <td>Transferències fora de la UE (NA)</td>
          <td>Marc de les transferències (NA)</td>
          <td><a href="mailto:dpo@kisio.com">dpo&#64;kisio.com</a></td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">PostHog</td>
          <td>Galetes estadístiques</td>
          <td>Estats-Units</td>
          <td>Transferències fora de la UE (NA)</td>
          <td>Marc de les transferències (NA)</td>
          <td>
            <a href="mailto:privacy@posthog.com">privacy&#64;posthog.com</a>
          </td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">Sentry</td>
          <td>Monitoring</td>
          <td>Estats-Units</td>
          <td>Transferències vers els Estats-Units</td>
          <td>SCC a través del DPA</td>
          <td><a href="mailto:legal@sentry.io">legal&#64;sentry.io</a></td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">Weblate</td>
          <td>Traducció</td>
          <td>Unió Europea</td>
          <td>Transferències fora de la UE (NA)</td>
          <td>En espera de la auditoria</td>
          <td>
            <a href="mailto:privacy@weblate.org">privacy&#64;weblate.org</a>
          </td>
        </tr>
        <tr>
          <td scope="row" class="text-primary font-weight-bold">
            Google Translate
          </td>
          <td>Traducció</td>
          <td>França</td>
          <td>Transferències fora de la UE (NA)</td>
          <td>Marc de les transferències (NA)</td>
          <td>
            <a href="mailto:data-protecció-office@google.com"
              >data-protecció-office&#64;google.com</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <p>
      <em
        >Conforme a l'original pery
        <a href="https://www.dipeeo.com/"><u>Dipeeo</u></a> &reg;</em
      >
    </p>
  </div>
</div>
